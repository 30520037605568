import React from "react";

const KlaviyoScript = () => (
  <script
    type="text/javascript"
    async=""
    src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RiqLWM"
  ></script>
);

export default KlaviyoScript;
