import React from "react";
import KlaviyoScript from "./KlaviyoScript";

const SEO = ({ title, children }) => {
  return (
    <>
      <title>{title}</title>
      <KlaviyoScript />
      {children}
      <KlaviyoScript />
    </>
  );
};

export default SEO;
